import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    children: [
      {
        path: 'formate',
        name: 'Formate',
        component: () => import(/* webpackChunkName: "formate" */ '../components/Formate.vue')
      },
      {
        path: 'difunde',
        name: 'Difunde',
        component: () => import(/* webpackChunkName: "difunde" */ '../components/Difunde.vue')
      },
      {
        path: 'comparte',
        name: 'Comparte',
        component: () => import(/* webpackChunkName: "comparte" */ '../components/Comparte.vue')
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import(/* webpackChunkName: "home" */ '../components/Blog.vue')
      },
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
      }
    ]
  },
  {
    path: '/pruebate',
    name: 'Pruebate',
    component: () => import(/* webpackChunkName: "pruebate" */ '../views/Inicio.vue')
  },
  {
    path: '/juego',
    name: 'Juego',
    component: () => import(/* webpackChunkName: "juego" */ '../views/Juego.vue'),
    children: [
      {
        path: 'noticia',
        name: 'noticia',
        component: () => import(/* webpackChunkName: "noticia" */ '../components/NewContainer.vue')
      },
      {
        path: 'resultado',
        name: 'resultado',
        component: () => import(/* webpackChunkName: "resultado" */ '../components/ResultadoNoticia.vue')
      },
      {
        path: 'combo',
        name: 'combo',
        component: () => import(/* webpackChunkName: "combo" */ '../components/ComboNoticia.vue')
      }
    ]
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import(/* webpackChunkName: "raking" */ '../views/Ranking.vue')
  },
  {
    path: '/submit',
    name: 'Submit',
    component: () => import(/* webpackChunkName: "submit" */ '../views/Submit.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

export default router
