import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag-next'
import mitt from 'mitt'
import { VueMasonryPlugin } from 'vue-masonry/src/masonry-vue3.plugin'

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(VueGtag, {
  property: {
    id: 'G-Y4QDCVVRM9'
  }
})
app.use(VueMasonryPlugin)
app.mount('#app')
