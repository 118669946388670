<template>
  <router-view/>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  created () {
    AOS.init()
  },
  mounted () {
    const soundGame = document.createElement('audio')
    soundGame.id = 'soundGame'
    soundGame.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/game.mp3'
    soundGame.setAttribute('preload', 'auto')
    soundGame.setAttribute('controls', 'none')
    soundGame.style.display = 'none'
    document.body.appendChild(soundGame)

    const soundMenu = document.createElement('audio')
    soundMenu.id = 'soundMenu'
    soundMenu.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/menu.mp3'
    soundMenu.setAttribute('preload', 'auto')
    soundMenu.setAttribute('loop', true)
    soundMenu.setAttribute('controls', 'none')
    soundMenu.style.display = 'none'
    document.body.appendChild(soundMenu)

    const soundButton = document.createElement('audio')
    soundButton.id = 'soundButton'
    soundButton.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/boton.mp3'
    soundButton.setAttribute('preload', 'auto')
    soundButton.setAttribute('controls', 'none')
    soundButton.style.display = 'none'
    document.body.appendChild(soundButton)

    const soundSwipe = document.createElement('audio')
    soundSwipe.id = 'soundSwipe'
    soundSwipe.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/swipe.mp3'
    soundSwipe.setAttribute('preload', 'auto')
    soundSwipe.setAttribute('controls', 'none')
    soundSwipe.style.display = 'none'
    document.body.appendChild(soundSwipe)

    const soundCorrecto = document.createElement('audio')
    soundCorrecto.id = 'soundCorrecto'
    soundCorrecto.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/correcto.mp3'
    soundCorrecto.setAttribute('preload', 'auto')
    soundCorrecto.setAttribute('controls', 'none')
    soundCorrecto.style.display = 'none'
    document.body.appendChild(soundCorrecto)

    const soundIncorrecto = document.createElement('audio')
    soundIncorrecto.id = 'soundIncorrecto'
    soundIncorrecto.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/incorrecto.mp3'
    soundIncorrecto.setAttribute('preload', 'auto')
    soundIncorrecto.setAttribute('controls', 'none')
    soundIncorrecto.style.display = 'none'
    document.body.appendChild(soundIncorrecto)

    const soundCombo = document.createElement('audio')
    soundCombo.id = 'soundCombo'
    soundCombo.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/combo.mp3'
    soundCombo.setAttribute('preload', 'auto')
    soundCombo.setAttribute('controls', 'none')
    soundCombo.style.display = 'none'
    document.body.appendChild(soundCombo)

    const soundPuntaje = document.createElement('audio')
    soundPuntaje.id = 'soundPuntaje'
    soundPuntaje.src = 'https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/sound/puntaje.mp3'
    soundPuntaje.setAttribute('preload', 'auto')
    soundPuntaje.setAttribute('controls', 'none')
    soundPuntaje.style.display = 'none'
    document.body.appendChild(soundPuntaje)
  }
}
</script>

<style>
@font-face {
  font-family: "Bebas Neue";
  src: url("assets/BebasNeue-Bold.ttf");
}
@font-face {
  font-family: "Helvetica";
  src: url("assets/HelveticaNeueLTPro-MdCn.otf");
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  margin: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 50%;
  margin-left: -100px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a:link, a:visited {
    text-decoration: none;
    color:inherit;
}

.etc_modulo_container {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
}
.etc_modulo_logo {
    padding: 40px;
    min-width: 250px;
    max-width: 400px;
}
.etc_modulo_descripcion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px;
    max-width: 600px;
}
.etc_modulo_title {
    color: #54ceea;
    font: 36px "Bebas Neue";
}
.etc_modulo_parrafo {
    text-align: justify;
    width: 80%;
    padding: 20px;
}

.borderblue,
.borderblue:before {
  border-radius: 10px;
  box-sizing: border-box;
}
.borderblue:before {
  border: 3px solid #0C1D3E;
  content: "";
  height: 100%;
  width: 100%;

  position: absolute;
  top: -6px;
  left: 6px;
  z-index: 0;
}

.botonazul {
  background-color: #54ceea;
  width: 300px;
  padding: 10px;
  color: #0C1D3E;
  font: 32px "Bebas Neue";
  margin: 0px auto;
  position: relative;
}

.botonarriba {
  margin-bottom: -35px;
}
.botonabajo {
  margin-top: -35px;
}

.etc_main_container {
  background-color: #0B132E;
  background-image: url('assets/website/global/patron_triangulos.svg');
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 60vh;

  padding: 70px 30px;
}

@media screen and (max-width: 820px) {
  .etc_modulo_descripcion {
    padding: 20px 0px;
  }
  .etc_modulo_parrafo {
    padding: 20px 0px;
  }
}
</style>
